<template>
    <div class="p-3">
        <div class="m-auto" style="width: 960px;">
            <el-row :gutter="16">
                <el-col :span="6">
                    <el-card shadow="never">
                        <div style="font-size: 32px;">
                            {{userNumberStats.managers}}
                        </div>
                        <div class="text-sm o-50">管理员</div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card shadow="never">
                        <div style="font-size: 32px;">
                            {{userNumberStats.registeredUsers}}
                        </div>
                        <div class="text-sm o-50">注册用户</div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card shadow="never">
                        <div style="font-size: 32px;">
                            {{userNumberStats.individualUsers}}
                        </div>
                        <div class="text-sm o-50">已实名个人</div>
                    </el-card>
                </el-col>
                <el-col :span="6">
                    <el-card shadow="never">
                        <div style="font-size: 32px;">
                            {{userNumberStats.enterpriseUsers}}
                        </div>
                        <div class="text-sm o-50">已实名企业</div>
                    </el-card>
                </el-col>
            </el-row>

            <el-row :gutter="16" class="mt-3">
                <el-col :span="12">
                    <el-card header="个人风险预警" shadow="never">
                        <echarts :option="individualUserRiskChartOption" style="height: 240px;" />
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card header="企业风险预警" shadow="never">
                        <echarts :option="enterpriseUserRiskChartOption" style="height: 240px;" />
                    </el-card>
                </el-col>
            </el-row>

            <el-card header="累计注册用户" shadow="never" class="mt-3">
                <echarts :option="registeredUserChartOption" style="height: 240px;" />
            </el-card>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                userNumberStats: {
                    managers: 0,
                    registeredUsers: 0,
                    individualUsers: 0,
                    enterpriseUsers: 0,
                    individualFarmers: 0,
                    enterpriseFarmers: 0,
                    individualSuppliers: 0,
                    enterpriseSuppliers: 0,
                    warrantors: 0,
                    experts: 0,
                },
                individualUserRiskChartOption: {},
                registeredUserChartOption: {},
                enterpriseUserRiskChartOption: {},
            };
        },
        methods: {
            async loadUserNumberStats() {
                let response = await this.$axios.get('/api/Stats/GetUserNumberStats', { params: { companyId: this.$store.state.companyId } });
                this.userNumberStats = response.data;
            },
            async loadIndividualUserRiskStats() {
                let response = await this.$axios.get('/api/Stats/GetIndividualUserRiskStats', { params: { companyId: this.$store.state.companyId } });
                let result = response.data;
                this.individualUserRiskChartOption = {
                    tooltip: {},
                    series: [{
                        type: 'pie',
                        data: result.map(p => ({ name: p.key, value: p.value }))
                    }]
                };
            },
            async loadRegisteredUserStats() {
                let response = await this.$axios.get('/api/Stats/GetRegisteredUserStats', { params: { companyId: this.$store.state.companyId } });
                let result = response.data;
                this.registeredUserChartOption = {
                    grid: { left: '60px', right: '20px', top: '10px', bottom: '40px' },
                    tooltip: {},
                    xAxis: { data: result.map(p => p.key) },
                    yAxis: {},
                    series: [{
                        type: 'line',
                        smooth: true,
                        areaStyle: {},
                        data: result.map(p => p.value)
                    }]
                };
            },
            async loadEnterpriseUserRiskStats() {
                let response = await this.$axios.get('/api/Stats/GetEnterpriseUserRiskStats', { params: { companyId: this.$store.state.companyId } });
                let result = response.data;
                this.enterpriseUserRiskChartOption = {
                    tooltip: {},
                    series: [{
                        type: 'pie',
                        data: result.map(p => ({ name: p.key, value: p.value }))
                    }]
                };
            },
        },
        created() {
            this.loadUserNumberStats();
            this.loadIndividualUserRiskStats();
            this.loadRegisteredUserStats();
            this.loadEnterpriseUserRiskStats();
        },
    };
</script>